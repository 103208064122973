import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'explorer',
      component: () => import('@/views/Explorer.vue'),
    },
    {
      path: '/log/:log',
      name: 'log',
      component: () => import('@/views/Explorer.vue'),
    },
    {
      path: '/map',
      name: 'map',
      component: () => import('@/views/Map.vue'),
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

export default router
